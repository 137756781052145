import gql from 'graphql-tag';
import { PagedApps } from '../types';
import { pagedApps } from './fragments';

export type SearchAppsResponse = {
  appResults?: PagedApps;
  clockResults?: PagedApps;
};

export type SearchAppsVariables = {
  text?: string | string[];
  developerProfileId?: string;
};

export const searchApps = gql`
  query searchApps(
    $text: String
    $developerProfileId: ID
    $clientIpAddress: String
  ) {
    appResults: searchApps(
      criteria: {
        type: APP
        text: $text
        developerProfileId: $developerProfileId
      }
      clientIpAddress: $clientIpAddress
    ) {
      ...pagedApps
    }
    clockResults: searchApps(
      criteria: {
        type: CLOCK
        text: $text
        developerProfileId: $developerProfileId
      }
      clientIpAddress: $clientIpAddress
    ) {
      ...pagedApps
    }
  }
  ${pagedApps}
`;
