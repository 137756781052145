import gql from 'graphql-tag';

export const appSummary = gql`
  fragment appSummary on App {
    id
    previewImage(scale: 1.0) {
      uri
    }
    icon(width: 120, height: 120) {
      uri
    }
    description
    developer {
      id
      name
    }
    name
    type
    isPremium
  }
`;

export const pagedApps = gql`
  fragment pagedApps on PagedApps {
    apps {
      ...appSummary
    }
    nextPages
    prevPages
    offset
  }
  ${appSummary}
`;
