import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { graphql, ChildDataProps, DataValue } from 'react-apollo';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import {
  searchApps,
  SearchAppsResponse,
  SearchAppsVariables
} from '../graphql/searchApps';
import { FilterType } from '../constants';
import * as constants from '../constants';
import AllCallout from '../components/AllCallout';
import { Section, NoStyleLink } from '../components/basic-components';

import { colors } from '../style-constants';

import searchIcon from '../images/icons/magnifying-glass.svg';
import checkIcon from '../images/icons/check-grey.svg';
import greenCheckIcon from '../images/icons/check-green.svg';
import { LoadingPage } from '../components/LoadingPage';
import { useI18n } from '../hooks';
import { ErrorPage } from './ErrorPage';
import { ResultsBody } from '../components/ResultsBody';
import { SubHeader } from '../components/Header';

type SearchTermsProps = {
  terms: string | string[];
};

type SearchProps = SearchTermsProps & {
  type: FilterType;
  path: string;
  clientIpAddress?: string;
};

type ChildProps = ChildDataProps<
  SearchProps & RouteComponentProps,
  SearchAppsResponse,
  SearchAppsVariables
>;

type FilterIconProps = {
  active?: boolean;
};

type FilterProps = FilterIconProps & {
  to: string;
  children?: ReactNode;
};

type FiltersProps = {
  type: FilterType;
  terms: string | string[];
  path: string;
};

const SplashSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SearchIcon = styled.img.attrs({
  src: searchIcon
})`
  width: 38px;
  margin-bottom: 10px;
`;

const CheckIcon = styled.img<FilterIconProps>`
  margin-right: 7px;
  position: relative;
  top: 2px;
`;

const AppTypeFilter = styled.div`
  display: flex;
`;

const FilterButton = styled(NoStyleLink)<FilterProps>`
  height: 34px;
  margin-right: 10px;
  padding: 7px 10px;

  border: 1px solid ${colors.linkWater};
  border-radius: 12px;
  background-color: white;

  color: ${(props) => (props.active ? colors.irisBlue : colors.linkWater)};
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const SearchSplash = () => {
  const { t } = useI18n();

  return (
    <SplashSection>
      <SearchIcon alt="" />
      <span>{t`Search for apps, clock faces or developers`}</span>
    </SplashSection>
  );
};

const Filter = ({ active, to, children }: FilterProps) => (
  <FilterButton to={to} active={active}>
    <CheckIcon src={active ? greenCheckIcon : checkIcon} alt="" />
    {children}
  </FilterButton>
);

const Header: React.FC<SearchTermsProps> = ({ terms }) => {
  const { t } = useI18n();

  const title = t`Searching for ${terms}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
    </Helmet>
  );
};

const Filters: React.FC<FiltersProps> = ({ type, terms }) => {
  const { t } = useI18n();

  const generateUrl = useCallback(
    (urlType: FilterType) => {
      return `/search?${queryString.stringify({
        terms,
        type: urlType !== constants.ALL ? urlType : undefined
      })}`;
    },
    [terms]
  );

  return (
    <SubHeader>
      <AppTypeFilter>
        <Filter to={generateUrl(constants.ALL)} active={type === constants.ALL}>
          {t`All`}
        </Filter>
        <Filter
          to={generateUrl(constants.CLOCKS)}
          active={type === constants.CLOCKS}
        >
          {t`Clocks`}
        </Filter>
        <Filter
          to={generateUrl(constants.APPS)}
          active={type === constants.APPS}
        >
          {t`Apps`}
        </Filter>
      </AppTypeFilter>
    </SubHeader>
  );
};

class Search extends React.Component<ChildProps> {
  public static defaultProps: SearchProps = {
    terms: '',
    type: constants.ALL,
    path: '',
    clientIpAddress: ''
  };

  public componentDidMount() {
    this.setSearchResultsCounts();
  }

  public componentWillReceiveProps(newProps: ChildProps) {
    this.setSearchResultsCounts(newProps.data);
  }

  private setSearchResultsCounts = (
    data: DataValue<SearchAppsResponse, SearchAppsVariables> | void
  ) => {
    const { terms } = this.props;
    const { clockResults, appResults } = data || this.props.data;

    const theWindow = window as any;

    if (!theWindow.dataLayer) {
      theWindow.dataLayer = [];
    }

    theWindow.dataLayer.push({
      searchAppResults: (appResults && appResults.apps.length) || 0,
      searchClockResults: (clockResults && clockResults.apps.length) || 0,
      searchTerms: terms
    });
  };

  public render() {
    const { terms, type, data, path } = this.props;
    const { clockResults, appResults, loading, error } = data;

    if (loading) {
      return <LoadingPage />;
    }

    if (error || !appResults || !clockResults) {
      return <ErrorPage />;
    }

    return (
      <React.Fragment>
        <Header terms={terms} />
        <Filters type={type} terms={terms} path={path} />
        <ResultsBody
          apps={appResults && appResults.apps}
          showApps={type !== constants.CLOCKS}
          clocks={clockResults && clockResults.apps}
          showClocks={type !== constants.APPS}
        />
        {type !== constants.ALL && (
          <AllCallout
            isApp={type === constants.APPS}
            clientIpAddress={this.props.clientIpAddress}
          />
        )}
      </React.Fragment>
    );
  }
}

export default graphql<
  SearchProps,
  SearchAppsResponse,
  SearchAppsVariables,
  ChildProps
>(searchApps, {
  options: (props) => ({
    variables: {
      text: props.terms,
      clientIpAddress: props.clientIpAddress
    }
  })
})(Search);
