import React, { Fragment } from 'react';
import styled from 'styled-components';

import { useI18n } from '../hooks';
import { App } from '../types';
import CardList from './CardList';
import { Section } from './basic-components';
import { below } from '../media-query-utils';
import { colors } from '../style-constants';
import AppTileCard from './AppTileCard';
import ClockTileCard from './ClockTileCard';
import { getPreviewImage } from '../utils';
import PremiumNotice from '../components/PremiumNotice';

const ResultsSection = styled(Section)`
  padding-top: 60px;

  ${below('mobile')} {
    padding-top: 30px;
    padding-bottom: 10px;
  }
`;

const ResultsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;

  ${below('mobile')} {
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }
`;

const ResultsTitle = styled.h2`
  align-self: flex-start;
  flex-grow: 1;

  margin: 0;
  font-size: 30px;
  font-weight: 600;
  color: ${colors.tangaroa};
`;

type ResultsBodyProps = {
  apps?: App[];
  showApps: boolean;
  clocks?: App[];
  showClocks: boolean;
};

type CardProps = {
  app: App;
};

const Card: React.FC<CardProps> = ({ app }) => {
  const TileCard = app.type === 'APP' ? AppTileCard : ClockTileCard;

  return (
    <TileCard
      key={app.id}
      img={getPreviewImage(app)}
      name={app.name}
      author={app.developer.name}
      id={app.id}
      isPremium={app.isPremium}
      data-appname={app.name}
      data-appdeveloper={app.developer.name}
      data-appid={app.id}
      data-apptype={app.type}
      data-applink
    />
  );
};

const Results = ({ show, countText, apps = [], displayPremiumNotice }: any) =>
  show &&
  apps && (
    <ResultsSection>
      <ResultsHeader>
        <ResultsTitle>{countText}</ResultsTitle>
        {displayPremiumNotice && <PremiumNotice />}
      </ResultsHeader>
      <CardList>
        {apps.map((app: App) => (
          <Card app={app} key={app.id} />
        ))}
      </CardList>
    </ResultsSection>
  );

export const ResultsBody: React.FC<ResultsBodyProps> = ({
  apps = [],
  showApps,
  clocks = [],
  showClocks
}) => {
  const { ngettext, msgid } = useI18n();

  const appsCount = apps ? apps.length : 0;
  const clocksCount = clocks ? clocks.length : 0;

  return (
    <Fragment>
      <Results
        show={showApps}
        countText={ngettext(
          msgid`${appsCount} App`,
          `${appsCount} Apps`,
          appsCount
        )}
        apps={apps}
      />
      <Results
        show={showClocks}
        countText={ngettext(
          msgid`${clocksCount} Clock Face`,
          `${clocksCount} Clock Faces`,
          clocksCount
        )}
        apps={clocks}
        displayPremiumNotice={clocks ? !!clocks.length : false}
      />
    </Fragment>
  );
};
